import { createStore } from 'vuex'

const store = createStore({
    state: {
        projects: [{
            "address": "Unit 03, Ground Floor, Greenbelt 3 Mall, Makati City",
            "finished": "",
            "images": [
                "patek-philippe-shangri-greenbelt-1.jpg",
                "patek-philippe-shangri-greenbelt-2.jpg",
                "patek-philippe-shangri-greenbelt-3.jpg",
                "patek-philippe-shangri-greenbelt-4.jpg",
                "patek-philippe-shangri-greenbelt-5.jpg",
                "patek-philippe-shangri-greenbelt-6.jpg",
                "patek-philippe-shangri-greenbelt-7.jpg",
                "patek-philippe-shangri-greenbelt-8.jpg",
                "patek-philippe-shangri-greenbelt-9.jpg",
            ],
            "name": "Patek Philippe - Greenbelt 3"
        }, {
            "address": "Unit 1110-1111, Ground Floor, East Wing, Edsa Shangri-La Plaza, Mandaluyong City",
            "finished": "",
            "images": [
                "gacorvera-logo.png"
            ],
            "name": "Balenciaga - Shangri-La EDSA"
        }, {
            "address": "Unit 02, Ground Floor, Greenbelt 3 Mall, Makati City",
            "finished": "",
            "images": [
                "loewe-greenbelt-1.jpg",
                "loewe-greenbelt-2.jpg",
                "loewe-greenbelt-3.jpg",
                "loewe-greenbelt-4.jpg",
                "loewe-greenbelt-5.jpg",
                "loewe-greenbelt-6.jpg",
                "loewe-greenbelt-7.jpg",
                "loewe-greenbelt-8.jpg",
            ],
            "name": "Loewe - Greenbelt 3"
        }, {
            "address": "Unit 1 & 2, 22nd Flr., Zuellig Bldg., Paseo de Roxas cor. Makati Ave., Makati City",
            "finished": "2019-10-01",
            "images": [
                "investing-in-women-1.jpg",
                "investing-in-women-2.jpg",
                "investing-in-women-3.jpg",
                "investing-in-women-4.jpg",
            ],
            "name": "Investing in Women"
        }, {
            "address": "Unit G-190 Ground Flr., Ayala Malls Bay Area, Brgy. Tambo, Paranaque City",
            "finished": "2019-10-01",
            "images": [
                "onitsuka-tiger-ayala-malls-bay-area-1.jpg",
                "onitsuka-tiger-ayala-malls-bay-area-2.jpg",
            ],
            "name": "Onitsuka Tiger - Ayala Malls Bay Area"
        }, {
            "address": "Unit 2217-2219, 2nd Flr., Ayala Malls Bay Area, Brgy. Tambo, Paranaque City",
            "finished": "2019-09-01",
            "images": [
                "asics-ayala-malls-bay-area-1.jpg",
                "asics-ayala-malls-bay-area-2.jpg",
                "asics-ayala-malls-bay-area-3.jpg",
            ],
            "name": "Asics - Ayala Malls Bay Area"
        }, {
            "address": "Ground Flr., C1 Bldg., 5th Ave. cor, 28th St., BGC, Taguig City",
            "finished": "2019-09-01",
            "images": [
                "asics-bgc-1.jpg",
                "asics-bgc-2.jpg",
            ],
            "name": "Asics - BGC"
        }, {
            "address": "Space 2164-2165, 2nd Flr., SM Mall of Asia Complex, Pasay City",
            "finished": "2019-04-01",
            "images": [
                "asics-mall-of-asia-1.jpg",
            ],
            "name": "Asics - Mall of Asia"
        }, {
            "address": "Stall No. 44, 4th Level, Andrews Ave., Brgy. 183, NAIA Terminal 3, Pasay City",
            "finished": "2019-03-01",
            "images": [
                "tim-hortons-naia-terminal-3-1.jpg",
                "tim-hortons-naia-terminal-3-2.jpg",
                "tim-hortons-naia-terminal-3-3.jpg",
                "tim-hortons-naia-terminal-3-4.jpg",
                "tim-hortons-naia-terminal-3-5.jpg",
                "tim-hortons-naia-terminal-3-6.jpg",
            ],
            "name": "Tim Horton's - NAIA Terminal 3"
        }, {
            "address": "Unit 150-151, Upper Ground Flr., SM Fairview, Greater Lagro, Quezon City",
            "finished": "2018-12-20",
            "images": [
                "asics-sm-fairview-1.jpg",
                "asics-sm-fairview-2.jpg",
            ],
            "name": "Asics - SM Fairview"
        }, {
            "address": "Unit 260, Bldg. A, SM Megamall, Mandaluyong City",
            "finished": "2018-11-01",
            "images": [
                "onitsuka-tiger-sm-megamall-1.jpg",
                "onitsuka-tiger-sm-megamall-2.jpg",
            ],
            "name": "Onitsuka Tiger - SM Megamall"
        }, {
            "address": "Bldg. G 13-14, SM By the Bay, MOA Complex, Pasay City",
            "finished": "2018-10-01",
            "images": [
                "specialized-sm-by-the-bay-1.jpg",
                "specialized-sm-by-the-bay-2.jpg",
            ],
            "name": "Specialized - SM By the Bay"
        }, {
            "address": "Unit 25 & 26, Acienda Designers' Outlet, KM 48 Emilio Aguinaldo Highway, Silang Cavite",
            "finished": "2018-10-01",
            "images": [
                "asics-acienda-1.jpg",
            ],
            "name": "Asics - Acienda"
        }, {
            "address": "Unit 24 & 25, Acienda Designers' Outlet, KM 48 Emilio Aguinaldo Highway, Silang Cavite",
            "finished": "2018-10-01",
            "images": [
                "onitsuka-tiger-acienda-1.jpg",
            ],
            "name": "Onitsuka Tiger - Acienda"
        }, {
            "address": "Space No. A8-A12, Ground Flr., San Lorenzo Place Condominium, Makati City",
            "finished": "2018-05-01",
            "images": [
                "tim-hortons-san-lorenzo-place-1.jpg",
                "tim-hortons-san-lorenzo-place-2.jpg",
                "tim-hortons-san-lorenzo-place-3.jpg",
                "tim-hortons-san-lorenzo-place-4.jpg",
            ],
            "name": "Tim Horton's - San Lorenzo Place"
        }, {
            "address": "NFGF006R & NFMZ006R, Ground Flr., Kia Theatre, Araneta Center Cubao, Quezon City",
            "finished": "2018-02-01",
            "images": [
                "tim-hortons-kia-theatre-1.jpg",
                "tim-hortons-kia-theatre-2.jpg",
                "tim-hortons-kia-theatre-3.jpg",
                "tim-hortons-kia-theatre-4.jpg",
            ],
            "name": "Tim Horton's - Kia Theatre"
        }, {
            "address": "5th Flr,, Building B, SM Megamall, Mandaluyong City",
            "finished": "2017-03-01",
            "images": [
                "seiko-sm-megamall-1.jpg",
                "seiko-sm-megamall-2.jpg",
                "seiko-sm-megamall-3.jpg",
            ],
            "name": "Seiko - SM Megamall"
        }, {
            "address": "Estancia Mall, Pasig City",
            "finished": "2016-12-01",
            "images": [
                "owndays-estancia-mall-1.jpg",
            ],
            "name": "Owndays - Estancia Mall"
        }, {
            "address": "2nd Flr., Uptown Mall, BGC",
            "finished": "2016-12-01",
            "images": [
                "the-nines-uptown-mall-bgc-1.jpg",
                "the-nines-uptown-mall-bgc-2.jpg",
                "the-nines-uptown-mall-bgc-3.jpg",
                "the-nines-uptown-mall-bgc-4.jpg",
                "the-nines-uptown-mall-bgc-5.jpg",
                "the-nines-uptown-mall-bgc-6.jpg",
                "the-nines-uptown-mall-bgc-7.jpg",
            ],
            "name": "The Nines - Uptown Mall BGC"
        }, {
            "address": "Landmark, Ayala, Makati City",
            "finished": "2016-10-01",
            "images": [
                "the-nines-kiosk-landmark-ayala-1.jpg",
            ],
            "name": "The Nines Kiosk - Landmark Ayala"
        }, {
            "address": "SM Southmall",
            "finished": "2016-07-01",
            "images": [
                "onitsuka-tiger-sm-southmall-1.jpg",
            ],
            "name": "Onitsuka Tiger - SM Southmall"
        }, {
            "address": "IT Hub Wilcom, Pasong Tamo",
            "finished": "2015-08-01",
            "images": [
                "franke-pasong-tamo-1.jpg",
            ],
            "name": "Franke - Pasong Tamo"
        }, {
            "address": "Shangri-La, BGC",
            "finished": "2015-08-01",
            "images": [
                "santoni-shangri-la-bgc-1.jpg",
            ],
            "name": "Santoni - Shangri-La BGC"
        }, {
            "address": "SM Aura",
            "finished": "2015-08-01",
            "images": [
                "onitsuka-tiger-sm-aura-1.jpg",
            ],
            "name": "Onitsuka Tiger - SM Aura"
        }, {
            "address": "Shangri-La, EDSA",
            "finished": "2015-08-01",
            "images": [
                "patek-philippe-shangri-la-edsa-1.jpg",
            ],
            "name": "Patek Philippe - Shangri-La EDSA"
        }, {
            "address": "Shangri-La, BGC",
            "finished": "2015-08-01",
            "images": [
                "lucerne-shangri-la-bgc-1.jpg",
            ],
            "name": "Lucerne - Shangri-La BGC"
        }],
        projectImageBaseUrl: 'https://one.sgp1.cdn.digitaloceanspaces.com/gacorvera/projects/',
        projectLightboxed: undefined
    },
    mutations: {
        setLightbox (state, project) {
            this.state.projectLightboxed = project
        }
    }
})

export default store
