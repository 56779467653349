<template>
    <div class="bg-black px-6 py-8 text-center text-sm text-white">
        <div class="container mx-auto">
            <p>
                All Rights Reserved
                <br/>
                G.A. Corvera Construction Corporation
                <br/>
                © 2016 - {{ year }}
                <br/>
                <br/>
                <span class="text-xs">
                    Site by <a href="https://marvin.ph" target="_blank">Marvin Isaac</a>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        year: new Date().getFullYear()
    })
}
</script>
