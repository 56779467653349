import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/Home.vue'
import NotFound from './pages/NotFound.vue'

const routes = [
    {
        path: '/',
        component: Home
    }, {
        path: '/not-found',
        component: NotFound
    }, {
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ './pages/Contact.vue')
    }, {
        path: '/projects',
        component: () => import(/* webpackChunkName: "projects" */ './pages/Project/Project.vue')
    }, {
        path: '/careers',
        component: () => import(/* webpackChunkName: "careers" */ './pages/Careers.vue')
    }, {
        path: '/:pathMatch(.*)*',
        redirect: '/not-found'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    }
})

export default router
