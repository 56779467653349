<template>
    <div class="bg-black bg-opacity-75 fixed h-screen p-2 top-0 w-full z-30
        md:p-8"
        @click="closeLigtbox()">
        <div class="max-w-screen-md mx-auto relative">
            <div class="absolute bg-red-500 cursor-pointer left-4 px-2 py-0 rounded top-4 z-40"
                @click="closeLigtbox()">
                <span class="font-bold leading-tight text-sm text-white">X</span>
            </div>
        </div>
        <div class="bg-white h-full max-w-screen-md mx-auto overflow-auto relative rounded"
            @click="stopPropagation($event)">
            <img class="border-b" :src="projectImageBaseUrl + imageFirst">
            <div class="p-4 md:p-8">
                <h1 class="font-bold leading-loose text-2xl">{{ project.name }}</h1>
                <div class="my-4">
                    <p class="mb-2">Location: {{ project.address }}</p>
                    <p v-if="projectFinished !== null">Date Finished: {{ projectFinished }}</p>
                </div>
                <img v-for="(image, index) in imageRemaining" :key="index"
                    :src="projectImageBaseUrl + image"
                    class="border my-4 mx-auto rounded">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        project: Object
    },
    computed: {
        projectImageBaseUrl () {
            return this.$store.state.projectImageBaseUrl
        }
    },
    data: () => ({
        imageFirst: undefined,
        imageRemaining: undefined,
        months: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ],
        projectFinished: undefined
    }),
    mounted () {
        document.body.classList.toggle('no-scroll')
        this.imageFirst = this.project.images[0]
        this.projectFinished = this.formatProjectFinished()

        // Create a -copy- of the original array,
        // otherwise shift() operation will remove images
        // Source: https://stackoverflow.com/a/7486130/11513232
        this.imageRemaining = this.project.images.slice()
        this.imageRemaining.shift()
    },
    unmounted () {
        document.body.classList.toggle('no-scroll')
    },
    methods: {
        closeLigtbox () {
            this.$store.commit('setLightbox', undefined)
        },
        formatProjectFinished () {
            if (this.project.finished === '') {
                return null
            }
            let date = new Date(this.project.finished)
            return this.months[date.getMonth()] + ' ' + date.getFullYear()
        },
        stopPropagation (event) {
            event.stopPropagation()
        }
    }
}
</script>
