<template>
    <div class="align-center flex flex-col h-screen justify-center text-center w-full">
        <h1 class="leading-loose text-xl uppercase
            md:text-2xl">
            Page not found
        </h1>
        <div class="bg-white border mx-auto my-4 rounded px-4
            md:w-1/6">
            <p class="leading-8 text-sm">
                <router-link to="/">
                    Home
                </router-link>
            </p>
        </div>
    </div>
</template>
