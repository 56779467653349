<template>
    <div class="aspect-h-3 aspect-w-4 bg-cover bg-top border max-w-screen-xl relative w-full
            md:aspect-h-9 md:aspect-w-16"
        :style="`background-image: url(' ${projectImageBaseUrl + project.images[0]} ')`">
        <div class="cursor-pointer" @click="openLightbox()">
            <div class="absolute bg-gradient-to-t from-black to-transparent
                bottom-0 pb-2 pt-4 px-4 text-justify text-white w-full z-10">
                <h1 class="font-bold text-left text-2xl">{{ project.name }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        project: Object
    },
    computed: {
        projectImageBaseUrl () {
            return this.$store.state.projectImageBaseUrl
        }
    },
    methods: {
        openLightbox () {
            this.$store.commit('setLightbox', this.project)
        }
    }
}
</script>
