<template>
    <div class="app-container min-h-screen">
        <app-navbar></app-navbar>
        <router-view></router-view>
        <app-footer></app-footer>
    </div>
</template>

<script>
import AppNavbar from './AppNavbar.vue'
import AppFooter from './AppFooter.vue' 

export default {
    components: {
        AppNavbar,
        AppFooter
    },
    computed: {
        projectLightboxed () {
            return this.$store.state.projectLightboxed
        }
    },
}
</script>

<style scoped>
.app-container {
    background: url('https://one.sgp1.cdn.digitaloceanspaces.com/gacorvera/gac-background.png') top left no-repeat;
    background-attachment: fixed;
}
</style>
